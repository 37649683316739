
import _ from "lodash";
import { mapGetters } from "vuex";
import segmentAnalytics from '@/libs/events';
import responsiveMixin from "@/mixins/responsive";
import { cleanHTML, sanitizeText } from "@/data/card-helpers";
import { staticPath, siteUrl } from "@/data/helpers";
import postApi from "@/data/api/post";
import PollStatus from './PollStatus';
import { timeLeft } from '@/shared/utils/general';
import PollActionCounts from './PollActionCounts';

export default {
  name: "PollContent",
  props: ["post", "source"],
  mixins: [responsiveMixin],
  components: {
    PollStatus,
    PollActionCounts,
  },
  data() {
    return {
      selectedAnswers: [],
      showPrivacyBox: false,
      triggeredClose: false,
    };
  },
  mounted() {
    // adding the ability to look for existing answers and update accordingly
    if (this.post.existingAnswer) {
      this.selectedAnswers = this.post.existingAnswer;
    }

    const { end_at } = this.post;
    const millisecondsLeft = Math.abs(new Date() - new Date(end_at));
    const minsLeft = Math.floor(millisecondsLeft/60/1000);
    // just a timed function to make sure the user can't vote as soon as the end of poll time hits
    if (minsLeft < 5) {
      setTimeout(() => {
        this.triggeredClose = true;
      }, millisecondsLeft);
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      votedPolls: 'votedPolls',
    }),
    // this is to be able to make sure any poll voted on via the FullPostModal reflects on any respective Feed
    // needed for making sure any poll shows the accurate vote count and the user's vote after they submit a vote
    poll() {
      const index = _.findIndex(this.votedPolls, poll => poll._id === this.post._id);
      if (index > -1) {
        return this.votedPolls[index];
      }
      return this.post;
    },
    loggedIn() {
      return this.user && this.user._id;
    },
    showEarlyResults() {
      return _.get(this.poll, 'showEarlyResults', false);
    },
    needsToAcceptPrivacyPolicy() {
      return (
        _.get(this.user, "legacy_user", false) &&
        !_.get(this.user, "privacy_policy.accept_privacy_policy", false)
      );
    },
    fullPostView() {
      return this.source === "fullPost";
    },
    // either the poll status is closed or the triggeredClose has been switched to true
    closedPoll() {
      return this.post.poll_status !== "OPEN" || this.triggeredClose;
    },
    // user can vote if the poll isn't closed and they haven't already responded
    canVote() {
      return !this.closedPoll && _.isEmpty(this.usersResponses);
    },
    usersResponses() {
      return _.filter(this.poll.votes, (v) => { return v.user_id === this.user._id; }) || [];
    },
    canToggleEarlyResults() {
      return this.canVote && _.get(this.user, 'profile.role') === 'staff';
    },
    saneTitle() {
      const { title, topics, mentions, embeds } = this.post;
      return sanitizeText({ content: title, topics, mentions, embeds });
    },

    saneSubtitle() {
      const { subtitle, topics, mentions, embeds } = this.post;
      return sanitizeText({ content: cleanHTML(subtitle || ''), topics, mentions, embeds });
    },
    singleAnswerPoll() {
      return this.post.poll_type === "SINGLE";
    },
    usersAnswer() {
      return "Yes, obviously";
    },
    totalVoteCount() {
      let total = 0;
      const voteCounts = _.get(this.poll, 'action_counts.votes', []);
      _.forEach(voteCounts, (response) => {
        total += response.count;
      });
      return total;
    },
    voteCountDisplay() {
      return this.totalVoteCount > 0 ? `${this.totalVoteCount} votes` : "No votes yet";
    },
  },
  methods: {
    staticPath,
    postContentLinkClick(e) {
      if (
        e.target &&
        e.target.href &&
        e.target.classList.contains("tm-topic-link")
      ) {
        e.preventDefault();
        this.$router.push(siteUrl(e.target.href, true));
      }
    },
    updateSingleAnswer(idx) {
      this.selectedAnswers = [idx];
      this.$emit("click", this.selectedAnswers);
    },
    updateMultiAnswer(idx) {
      const index = _.findIndex(this.selectedAnswers, (i) => i === idx);
      if (index > -1) {
        this.selectedAnswers.splice(index, 1);
      } else {
        this.selectedAnswers.push(idx);
      }
      this.$emit("click", this.selectedAnswers);
    },
    // this is during the vote
    answerSelected(idx) {
      const search = _.findIndex(this.selectedAnswers, (i) => i === idx);
      return search > -1;
    },
    // this is for after they've voted
    userSelectedResponse(id) {
      return _.findIndex(this.usersResponses, (r) => r.response === id) > -1;
    },
    // for responses without any votes, we still want there to be a little fill
    resultFill(id) {
      const vote = _.find(_.get(this.poll, 'action_counts.votes', []), (v) =>  v.response === id) || {} ;
      if (vote.count) {
        return this.percentOfTotal(id);
      }
      return 2.00;
    },
    percentOfTotal(id) {
      const vote = _.find(_.get(this.poll, 'action_counts.votes', []), (v) =>  v.response === id) || {} ;
      return Math.round((( (vote.count || 0) / this.totalVoteCount) * 100.0));
    },
    vote() {
      if (!this.loggedIn) {
        this.$store.dispatch("openModal", {
          id: "login-registration-onboarding",
          args: { action: "login" },
        });
        return;
      }

      if (this.needsToAcceptPrivacyPolicy) {
        let callback;
        // if on FullPost view, remove the /content/:contentid upon opening the accept privacy policy modal
        // additionally, we'll want to add the URL back once the FullPost comes back up
        if (
          this.fullPostView &&
          window.location.pathname.includes("/poll")
        ) {
          const path = `${window.location.origin}${window.location.pathname}`;
          callback = () => { history.pushState({}, "", path) };
          const url = path.split("poll/")[0];
          history.pushState({}, "", url);
        }
        this.$store.dispatch("openModal", {
          id: "accept-privacy-policy",
          args: { callback },
        });
      } else {
        this.submitVote();
      }
    },
    openFullPost() {
      // storing any existing answers on the dispatched data, so that we can reference them again
      const data = { ...this.post, existingAnswer: this.selectedAnswers };
      this.$store.dispatch("updateCurrentFullPost", {
        data,
      });
      this.$store.dispatch("openModal", { id: "view-full-post" });
    },
    async submitVote() {
      const responses = [];

      _.forEach(this.selectedAnswers, a => {
        responses.push(this.post.responses[a]._id);
      });

      await postApi.submitVote(this.post._id, responses, this.$nodeAxios)
        .then((updatedPost) => {

          const answers = [];

          _.forEach(this.selectedAnswers, a => {
            answers.push(this.post.responses[a]);
          });

          this.$store.commit('setPollVote', { post: updatedPost })
          segmentAnalytics.vote({
            target: {
              name: answers,
              type: 'poll'
            },
            post: {
              id: this.post._id,
              type: this.post.poll_type === 'SINGLE' ? 'poll_single' : 'poll_multi',
              title: this.post.title,
              user: {
                username: updatedPost.author_id.username,
                id: updatedPost.author_id.id
              },
              topic: {
                names: updatedPost.topics?.map(topic => topic.hash_tag),
              },
            },
            context: {
              ...updatedPost.groups[0]?._id ? {
                group: {
                  id: updatedPost.groups[0]._id,
                  name: updatedPost.groups[0].name,
                },
              } : {},
            },
            userId: this.user._id,
            sessionId: this.user.sessionId,
          })
           });
    },
  },
  watch: {
    canToggleEarlyResults: {
      immediate: true,
      handler(val) {
        this.$emit('update', val);
      }
    },
  }
};
