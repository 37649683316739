
export default {
  props: ["src", "width", "height", "alt", "classes", "styles"],
  data: () => ({ observer: null, intersected: false }),
  computed: {
    srcImage() {
      return this.intersected ? this.src : "";
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      for (const entrie of entries) {
        if (entrie.isIntersecting) {
          this.intersected = true;
          this.observer.disconnect();
        }       
      }
    });

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
};
