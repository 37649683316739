// eslint-disable-next-line import/prefer-default-export
import { dateDifference } from '@/data/helpers';

export const removeHtmlStructure = (content: String) =>
  content.replace(/(<([^>]+)>)/gi, '').replace(/&#8217;/g, "'").replace(/&#8220;/g, '"').replace(/&#8221;/g, '"')
  .replace(/&#8212;/g, '—');

export const timeLeft = (post: any) => {
  const { end_at } = post;

  const endAt = new Date(end_at);
  const { q, t } = dateDifference(new Date(), endAt);

  if (t === 'second') return '1 minute left';
  const plus = q + 1;
  return `${plus} ${t}${plus > 1 ? 's' : ''} left`;
};