import { CommunityVideoStoryPropsI } from '@/userExperienceRedesign/modules/CommunityVideoStory/types';
import CommunityStoryController from './communityStory';

export default class CommunityVideoStoryController {
  protected data?: CommunityVideoStoryPropsI;

  constructor(post: any) {
    this.parse(post);
  }
  public get value() {
    return this.data;
  }

  protected parse(post: any) {
    const story = new CommunityStoryController(post);
    const playInStream = post.card_data['play-video-in-stream'] === 'yes';
    this.data = {
      ...story.value as CommunityVideoStoryPropsI,
      playInStream,
      video: post.video,
      fullViewButtonLabel: playInStream ? 'Read video transcript' : 'Watch video',
      // imageSrc: post.video.media_thumb,
    };
    if (post.sponsored !== null) {
      this.data.contentPreview = post.created_at.toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric" });
    }
  }
}
